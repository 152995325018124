.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 490px;
  padding-inline: 10px;
  gap: 10px;
}

.column {
  width: min(230px, 100%);
}

.login_column {
  width: min(490px, 100%);
  margin: 0;
}

.input_label {
  cursor: default;
  display: block;
  font-weight: 500;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3px;
  user-select: none;
}

.input {
  width: 100%;
  padding: 7px 8px;
  background: none;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 200;
}

.input[type="text"] {
  background: #fff;
}

/* Firefox */
input[type="number"] {
  --moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  height: 100%;
  font-size: 1.6rem;
  width: 75%;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
@media screen and (max-width: 600px) {
  .content {
    width: 95%;
  }
}

.form_wrapper {
  background-color: #fff;
  box-shadow: 0 0 40px -10px #c9c9c9;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
  width: min(560px, 100%);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.form_header {
  font-weight: 500;
  font-size: 2rem;
  padding: 0 10px 0 10px;
}

.button {
  width: 100%;
  cursor: pointer;
  padding: 1rem 0.5rem;
  border: 0;
  border-radius: 4px;
  background-color: var(--blue-base);
  color: white;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.25s;
}

.button:hover {
  background-color: var(--blue-hover);
}

.login_available {
  border: 2px solid var(--blue-base);
  padding: 6px 7px;
}

.login_not_available {
  border: 2px solid #df4f5d;
  padding: 6px 7px;
}
