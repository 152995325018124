@import "react-datepicker/dist/react-datepicker.css";

/* strzałki na górze */
.react-datepicker__navigation {
  width: 60px;
  height: 60px;
}

/* cały container */
.react-datepicker {
  width: 370px;
  height: 100%;
  border: 0;
  border-right: 1px solid #c0c0c0;
  border-radius: 0;
}

.react-datepicker__header {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  background-color: var(--blue-tint);
  border-bottom: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  border-radius: 0 !important;
  font-weight: 300;
}

.react-datepicker__month-container {
  width: 370px;
  border-radius: 0;
}

.react-datepicker__month {
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin: 0;
}

.react-datepicker__day-name {
  width: 50px;
  height: 24px;
  margin: 0;
  font-size: 1.6rem;
  color: var(--font-base);
}

.react-datepicker__current-month {
  font-size: 1.8rem;
  height: 40px;
  color: var(--font-base);
}

.react-datepicker__week {
  box-sizing: border-box;
  height: 33px;
  margin: 0;
}

.react-datepicker__day {
  width: 50px;
  height: 33px;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  text-align: center;
  user-select: none;
  font-weight: 300;
  color: var(--font-base);
  transition: background-color 0.3s;
}

.react-datepicker__day:hover {
  background-color: var(--blue-tint);
}

.react-datepicker__day--disabled {
  color: #c0c0c0;
  cursor: not-allowed;
}
.react-datepicker__day--disabled:hover {
  background-color: initial;
}

.react-datepicker__day--selected {
  color: #fff;
  background-color: var(--blue-base);
  transition: background-color 0.3s;
}

.react-datepicker__day--selected:hover {
  background-color: var(--blue-hover);
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
  background-color: var(--blue-date-range);
  transition: background-color 0.3s;
}

.react-datepicker__day--in-range:hover {
  color: #fff;
  background-color: var(--blue-date-range-hover);
}

.react-datepicker__day--keyboard-selected {
  background-color: inherit;
}

.react-datepicker__time-list-item {
  font-size: 1.2rem;
}

.react-datepicker__day--outside-month:not(.react-datepicker__day--in-range) {
  color: #c0c0c0;
}

.react-datepicker__time-list {
  height: 210px !important;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--blue-base) !important;
}
.react-datepicker__time-list-item--selected:hover {
  background-color: var(--blue-hover) !important;
}

.react-datepicker__time-container {
  border-left: 1px solid var(--gray-base);
}

.react-datepicker-time__header {
  font-size: 1.4rem;
  margin: auto;
}
