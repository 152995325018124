.content_wrapper {
  height: fit-content;
  background-color: var(--blue-tint);
  padding: 20px 15px;

  border-bottom: 1px solid var(--gray-base);
}

.content_new_offer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.new_offer_content {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.new_offer_content_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: fit-content;
  margin: 0;
  align-content: center;
}

.new_offer_summary {
  display: flex;
  flex-direction: column;
  width: 490px;
  padding: 0 10px 0 10px;
  gap: 15px;
}

.transport_summary {
  cursor: default;
  display: block;
  font-weight: 500;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3px;
  user-select: none;
}

.new_offer_price {
  border: 1px solid var(--gray-base);
  border-radius: 4px;
  padding: 10px 12px;
  background-color: #fff;
}

.new_offer_price_header {
  margin-bottom: 3px;
  height: 20px;
}

.new_offer_summary_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-grow: 1;
}

.icon_wrapper {
  display: flex;
  justify-content: center;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--blue-base);
}

.table_transport_wrapper {
  padding: 0 10px;
}

.table_transport {
  border-spacing: 0;
  background-color: #fff;
  border: 1px solid var(--gray-base);
  border-radius: 4px;
  width: 470px;
}

.tr_transport {
  border-bottom: 1px solid var(--gray-base);
}

.td_transport {
  padding: 8px;
  height: 35px;
  max-height: 35px;
  border-right: 1px solid var(--gray-base);
  border-bottom: 1px solid var(--gray-base);
}

.td_transport_button {
  cursor: pointer;
  padding: 0 5px;
  height: 35px;
  border-right: 1px solid var(--gray-base);
  border-bottom: 1px solid var(--gray-base);
}

.new_addr_wrapper {
  width: 490px;

  padding: 0 10px 0 10px;
}

.new_addr_or {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  color: var(--gray-dark);
}

.new_addr {
  cursor: pointer;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid var(--gray-base);
  border-radius: 4px;
  color: var(--font-base);
  font-size: 1.5rem;
  text-align: center;
}

.new_addr:hover {
  background-color: var(--blue-tint-tint);
}

.comment_title {
  margin-inline: 10px;
  margin-bottom: 3px;
}

.comment_wrapper {
  background-color: #fff;
  margin-inline: 10px;
  padding: 10px;
  border: 1px solid var(--gray-base);
  border-radius: 4px;
}

.comment {
  font-weight: 300;
  word-break: break-all;
}

.notes_title {
  margin-inline: 10px;
  margin-bottom: 3px;
}

.notes_wrapper {
  background-color: #fff;
  margin-inline: 10px;
  padding: 10px;
  border: 1px solid var(--gray-base);
  border-radius: 4px;
}

.notes {
  font-weight: 300;
  word-break: break-all;
}

.enterAddr {
  height: 0px;
  opacity: 0;
}

.enterActiveAddr {
  opacity: 1;
  height: 175px;
  transition:
    height 300ms,
    opacity 500ms,
    margin 300ms;
}

.exitAddr {
  height: 175px;
  opacity: 1;
}

.exitActiveAddr {
  height: 0px;
  opacity: 0;
  margin: 0;
  transition:
    height 300ms,
    padding 300ms,
    margin 300ms;
}

/* ------------------- List with addresses ---------------- */
.list_wrapper {
  width: 490px;
  padding: 0 10px 0 10px;
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
}

.list {
  width: 100%;
  height: 160px;
  background: none;
  border: 0;
  margin: 0;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  overflow-y: hidden;
}

.list:hover {
  overflow-y: scroll;
}

.list::-webkit-scrollbar {
  width: 7px;
}

.list::-webkit-scrollbar-thumb {
  background: var(--blue-base);
  border-radius: 4px;
}

.list_item {
  text-align: left;
  font-weight: 200;
  background-color: #fff;
  padding: 7px 8px;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
  font-size: 1.4rem;
  min-height: 32px;
  user-select: none;
  word-wrap: break-word;
}

.chexbox_list_item {
  text-align: left;
  font-weight: 200;
  background-color: #fff;
  padding: 7px 8px;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
  font-size: 1.4rem;
  min-height: 32px;
  user-select: none;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
}

.list_item:hover {
  background-color: #ebf4fc;
}

.expi_input {
  width: 100%;
  padding: 7px 8px;
  background: none;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 400;
  background-color: #fff;
}

.date_label {
  cursor: default;
  display: block;
  font-weight: 500;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3px;
  user-select: none;
}

.expi_select {
  background-color: #fff;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: var(--font-base);
}

.expi_select:hover {
  background-color: var(--blue-tint);
}

.expi_option {
  height: 36px;
}

.expi_option:hover {
  background-color: var(--blue-tint);
}
