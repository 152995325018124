.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  color: #303657;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  margin-bottom: 15px;
}

.row_items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 15px;
}

.input {
  max-width: 150px;
  padding: 9px 8px;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #f8f8ff;
}

.input:focus {
  border: 1px solid var(--blue-base);
  border-radius: 4px;
}

#button_active {
  background-color: #002c7d;
}

.table_wrapper {
  background-color: #fff;
  box-shadow: 0 0 40px -10px #c9c9c9;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
  max-width: 100%;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.thead {
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.th {
  max-width: 100%;
  overflow: hidden;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px 15px;
}

.tr {
  max-width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tr:hover {
  background-color: #ebf4fc;
}

.td {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 25px 15px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  font-weight: 300;
}

.tr_exp {
  text-align: center;
  cursor: default;
  user-select: none;
  background-color: #f8f8ff;
}

.td_exp {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px 15px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  font-weight: 300;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.td_exp_content {
  display: flex;
  flex-flow: column;
}

.td_exp_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.td_exp_heading_content {
  display: flex;
  gap: 15px;
}

.button,
.td_exp_button {
  cursor: pointer;
  padding: 10px 12px;
  border: 0;
  border-radius: 4px;
  background-color: #3699ff;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.25s;
}

.button:hover,
.td_exp_button:hover {
  background-color: #228af1;
}

.td_exp_button {
  min-width: 100px;
}

#pricing_button_active {
  background-color: #002c7d;
}

#edit {
  background-color: #2fc193;
}

#edit:hover {
  background-color: #00a997;
}

#delete {
  background-color: #df4f5d;
}

#delete:hover {
  background-color: #f13447;
}

.td_exp_services {
  width: 50%;
}

.td_exp_price {
  padding: 10px 8px;
}

.mainEnter {
  opacity: 0;
}

.mainEnterActive {
  opacity: 1;

  transition: opacity 300ms;
}

.mainExit {
  opacity: 1;
}

.mainExitActive {
  opacity: 0;
}

.csstrEnter {
  opacity: 0;
}

.csstrEnterActive {
  opacity: 1;

  transition: opacity 300ms;
}

.csstrExit {
  opacity: 1;
}

.csstrExitActive {
  opacity: 0;
  transition: opacity 300ms;
}

.statuses {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.status_wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}

.status_header {
  width: 10px;
  height: 10px;
}

.status_table {
  width: 6px;
  height: 50px;
}
