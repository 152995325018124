.content_wrapper {
  height: fit-content;
  background-color: var(--blue-tint);
  padding: 20px 15px;

  border-bottom: 1px solid var(--gray-base);
}

.content_offers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;

  border: 1px solid var(--gray-base);
  border-radius: 4px;
  margin-bottom: 15px;
}

.content_contact_person {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;

  border: 1px solid var(--gray-base);
  border-radius: 4px;
  margin: 10px 0;
}

.mapPng {
  width: 100%;
  height: 320px;
  object-fit: contain;
}

/* -------------- offers -------------------- */

.content {
  background-color: #fff;
  border: 1px solid var(--gray-base);
  border-radius: 4px;
  margin-bottom: 15px;
  margin-top: 20px;
}

.header {
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-bottom: 1px solid var(--gray-base);
}

.item {
  padding: 20px 15px;
  font-weight: 300;
}

.notes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  min-height: 60px;
  max-height: 120px;
  border: 1px solid #fff;
  padding: 7px;
  font-weight: 300;
  margin: 0 10px 0 10px;
}

.notes_buttons {
  display: flex;
  justify-content: right;
  padding-top: 15px;
  gap: 15px;
}

.status {
  width: 6px;
  height: 50px;
}

.contact_person {
  padding: 10px;
  text-align: center;
}

/*----------------- table -------------------*/

.table {
  z-index: 2;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.thead {
  border-bottom: 1px solid var(--gray-base);
}

.tbody {
  max-height: 350px;
  overflow-y: scroll;
}

.th {
  overflow: hidden;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px 15px;
}

.td {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--gray-base);
  height: 70px;
  text-overflow: ellipsis;
  text-align: left;
  padding: 10px 15px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  font-weight: 300;
}

.tr_td {
  height: 50px;
}

.tr_td:hover {
  background-color: #f8f8ff;
}

/* ------------------ tooltip ----------------- */

.td .tooltiptext,
.td .tooltiptext_centered {
  visibility: hidden;
  font-style: normal;
  background-color: var(--nav-base);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 6px;
  opacity: 0%;

  /* Position the tooltip */
  position: absolute;
  top: 55px;

  transform: translateY(-50%);

  transition:
    opacity 0.4s,
    transform 0.3s;
  z-index: 999;
}

.td:hover .tooltiptext,
.td:hover .tooltiptext_centered {
  visibility: visible;
  opacity: 90%;
  transform: translateY(10px);
  padding: 20px;
  min-width: 200px;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: nowrap;
}

.td:hover .tooltiptext {
  left: 0;
}

.td .tooltiptext_centered {
  left: -20%;
}

.td_with_info {
  cursor: pointer;
  font-style: italic;
}
